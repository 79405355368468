const content = {
  emitCert: {
    title: 'Emitir certificado',
    link: 'https://mp.syngularid.com.br'
  },
  gerencial: {
    title: 'Gerencial',
    link: 'https://gerencial.scontti.com'
  }
}

export default content
