import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import DigitalCertificate from '../components/DigitalCertificate'
import { Wrapper } from '../styles/layout'
import Header from '../styles/Header'

export default function CertificadoDigital() {
  return (
    <Layout>
      <Seo title="Certificado digital" />
      <Wrapper>
        <Header as="h1" content="Certificado digital" />
        <DigitalCertificate />
      </Wrapper>
    </Layout>
  )
}
