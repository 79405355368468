import React from 'react'

import content from './content'
import { EmitCertificate, Gerencial } from './Icons'

import { CardGroup, Card, CardContent, CardActions } from '../../styles/card'
import Clickable from '../Clickable'

export default function DigitalCertificate() {
  return (
    <CardGroup>
      <Card>
        <CardContent>
          <EmitCertificate />
          <h2>{content.emitCert.title}</h2>
          <CardActions>
            <Clickable
              as="a"
              primary
              href={content.emitCert.link}
              content="Acessar"
              target="_blank"
            />
          </CardActions>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Gerencial />
          <h2>{content.gerencial.title}</h2>
          <CardActions>
            <Clickable
              as="a"
              primary
              href={content.gerencial.link}
              content="Acessar"
              target="_blank"
            />
          </CardActions>
        </CardContent>
      </Card>
    </CardGroup>
  )
}
