import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
`

export const CardGroup = styled.div`
  display: flex;
  flex-direction: horizontal;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
`

export const Card = styled.article`
  margin: 10px;
  max-width: 350px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  display: inline-block;
`

export const CardContent = styled.div`
  margin: 10px;
  text-align: center;

  svg {
    width: 60%;
  }
`

export const CardActions = styled.div`
  display: flex;
  flex-direction: horizontal;

  a {
    flex-grow: 1;
  }

  a:first-child {
    margin-right: 10px;
  }

  a:only-child {
    margin-right: 0;
  }
`
